<template>
  <div class="content contribute-list">
    <a-tabs :active-key="key" @tabClick="handleClick">
      <a-tab-pane :key="1" tab="感悟分享">
        <contribute-data :type="1" />
      </a-tab-pane>
      <a-tab-pane :key="2" tab="摄影分享">
        <contribute-data :type="2" />
      </a-tab-pane>
      <a-tab-pane :key="3" tab="视频分享">
        <contribute-data :type="3" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
    import ContributeData from './laytouts/ContributeData';
    export default {
        name: "ContributeList",
        components: { ContributeData },
        data() {
            return {
                key: 1
            }
        },
        methods: {
            handleClick(e) {
                this.key = e;
            },
        }
    }
</script>

<style lang="less">
.contribute-list {
  .ant-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ant-tabs-content {
    flex: 1;
    min-height: 0;
  }
}
</style>
