<template>
  <data-list
    ref="dataList"
    style="height: 100%"
    :url="url"
    :query="query"
    :hide-action="!audit"
    hide-show-detail
    hide-edit
    :default-query="{status: 1}"
    :un-audit-value="1"
    :hide-audit="false"
    :columns="columns"
    :requestConfig="{ noTempleFilter: true }"
    @update="handleUpdate">
    <template v-slot:query="{ form }">
      <a-form-model-item v-if="audit">
        <a-select v-model="form.status" :options="statusOptions" placeholder="选择状态筛选" allowClear @change="updateList" style="width: 180px"></a-select>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    export default {
        name: "ContributeData",
        props: {
            audit: Boolean,
            type: Number
        },
        data() {
            return {
                statusOptions: [
                    {value: 1, label: '待审核'},
                    {value: 2, label: '已通过'},
                    {value: 3, label: '未通过'},
                ],
                url: "/admin/mini-contribute",
            }
        },
        computed: {
            query() {
                const type = this.type;
                const audit = this.audit;
                let res = `&expand=user,likeCount&filter[type]=${type}`;
                if (!audit) {
                    res += "&filter[status]=2"
                }
                return res;
            },
            columns() {
                const audit = this.audit;
                const type = this.type;
                const res = [
                    { title: "用户昵称", dataIndex: "user", customRender: text => text?.uname || '匿名' },
                    { title: "提交时间", dataIndex: "create_time" },
                ];
                if (type === 1) {
                    res.push({ title: "感悟", dataIndex: "content" })
                } else {
                    res.push({ title: "作品名称", dataIndex: "name" });
                    if (type === 2) {
                        res.push(
                            {
                                title: '图片',
                                dataIndex: 'img',
                                customRender: (text, record) => {
                                    const arr = text ? text.split(",") : []
                                    return arr.length > 0 ?
                                        <a-badge
                                            count={arr.length}
                                            onClick={() => this.previewPhoto(arr, record)}>
                                            <a-icon type="picture" style="font-size: 64px;cursor:pointer;"/>
                                        </a-badge> : '无'
                                }
                            }
                        )
                    } else {
                        res.push(
                            {
                                title: '视频',
                                dataIndex: 'video',
                                customRender: (text, record) => text ? <s-img src={record.img} video={text} /> : '--'
                            }
                        )
                    }
                }
                if (!audit) {
                    res.push(
                        { title: "点赞数", dataIndex: "likeCount" },
                        {
                            title: '是否展示',
                            dataIndex: 'is_agree',
                            customRender: (text, record) => {
                                return <a-switch loading={!!record.loading} checked={text == 1} onChange={(val) => this.handleDisplayChange(val,record)}/>
                            }
                        },
                    );
                }
                return res;
            }
        },
        methods: {
            handleUpdate(item) {
                this.$emit("update", item);
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            handleDisplayChange(val, item) {
                const url = `/admin/mini-contribute/${item.id}`;
                this.$set(item, 'loading', true);
                this.$axios({
                    url,
                    method: "PATCH",
                    data: {
                        is_agree: val * 1
                    }
                }).then(res => this.$set(item, 'is_agree', res.is_agree)).finally(() => this.$set(item, 'loading', false));
            },
            previewPhoto(img, record) {
                const title = record.name;
                const list = img.map((item, i) => Object({title: title + (i + 1), url: item}));
                this.$store.dispatch("showImgList", list);
            },
        }
    }
</script>

<style scoped>

</style>
